import React from 'react'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'

class SolutionText extends React.Component {  
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const textJustify = this.props.sectionCenter ? 'text-center' : 'text-left'
    const converter = new Showdown.Converter()
    const Heading = this.props.data.htag ? this.props.data.htag : 'h2'
    const headingClass = this.props.data.htag === 'h3' ? 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3' : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3'    

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
            {!this.props.data.title.includes('***') &&
              <Heading className={`${headingClass}`}>
                {this.props.data.title}
                </Heading>
              }
              {this.props.data.subtitle && 
              <div className={`text-medium ${textClass} ${textJustify}`}>{this.props.data.subtitle}</div>
              }

              {this.props.data.description &&
                <div
                  className="text-medium richtext"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(this.props.data.description),
                  }}
                />
              }
              {(this.props.data.link || this.props.data.document) && (
                <>
                  {this.props.data.link ? (
                    <Link to={this.props.data.link} className="nav-link btn btn-mdb-color btn-sm-block mt-5" >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      {this.props.data.linktext}
                    </Link>
                  ) : (
                    <a href={this.props.data.document.publicURL} className="nav-link btn btn-mdb-color btn-sm-block mt-5" target="_blank" >
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      {this.props.data.linktext}
                    </a>
                  )}
                </>
              )}             
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default SolutionText